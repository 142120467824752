<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
    style="max-width: 600px"
  >
    <v-card
      class="pa-4"
      color="transparent"
      outlined
    >
      <v-card-title class="justify-center">
        <span class="headline">Login</span>
      </v-card-title>

      <v-card-text>
        <v-text-field
          ref="username"
          v-model="username"
          :error-messages="errorMessages"
          :rules="[() => !!username || 'Enter username']"
          hint="Press Enter to continue"
          clearable
          class="pb-2"
          @keyup.enter="corpLogin"
        />

        <v-btn
          width="100%"
          height="90%"
          color="rgb(255, 255, 255, 0.25)"
          @click="corpLogin"
        >
          Enter
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'PagesLogin',

    data: function () {
      return {
        username: '',
        password: '',
        errorMessages: '',
      }
    },

    computed: {
      form () {
        return {
          username: this.username,
        }
      },
    },

    methods: {
      corpLogin () {
        // console.log(this.username)
        // if (this.username === 'thao') this.$router.push('/')
        this.$refs.username.validate(true)
        if (this.form.username) {
          this.$store.dispatch('login', this.username)
            .then(() => {
              this.$router.push('/')
            })
        }
      },
    },
  }
</script>
